import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { LANGUAGES, LOCAL_STORAGE } from "utils/constants"
import dictionaryplPL from "./pl-PL.json"
import dictionarydeDE from "./de-DE.json"
import dictionaryenGB from "./en-GB.json"
import dictionaryesES from "./es-ES.json"
import dictionaryfrFR from "./fr-FR.json"
import dictionaryhrHR from "./hr-HR.json"
import dictionaryukUA from "./uk-UA.json"
import dictionaryisvLATIN from "./isv-LATIN.json"
import dictionaryisvCYRILLIC from "./isv-CYRILLIC.json"

const resources = {
    "pl-PL": {
        translation: dictionaryplPL,
    },
    "de-DE": {
        translation: dictionarydeDE,
    },
    "en-GB": {
        translation: dictionaryenGB
    },
    "es-ES": {
        translation: dictionaryesES
    },
    "fr-FR": {
        translation: dictionaryfrFR
    },
    "hr-HR": {
        translation: dictionaryhrHR
    },
    "uk-UA": {
        translation: dictionaryukUA
    },
    "isv-LATIN": {
        translation: dictionaryisvLATIN
    },
    "isv-CYRILLIC": {
        translation: dictionaryisvCYRILLIC
    }
}

i18n.use(initReactI18next).init({
    resources,
    lng: window.localStorage.getItem(LOCAL_STORAGE.language) || LANGUAGES[0],
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
    },
})

export default i18n